<template>
  <div class="Senator">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" :moduleName="$route.meta.name">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container">
            <el-row >
              <el-col :sm="16" :xs="24" class="pt-3 pcLeft" :md="16">
                <el-input clearable @clear="memberSearch" @keyup.enter.native="memberSearch" class="w-100"
                  :placeholder="$t('Senator.a72@search_senator')" v-model="search"></el-input>
              </el-col>
              <el-col class="pt-3" v-if="user_senate==0||user_senate==3" :sm="8" :xs="24" :md="8">
                <el-button style="
                    height: 45px;
                    border: 1px solid #50b5ff;
                    background: none;
                    border-radius: 10px;
                    color: #50b5ff;
                  " type="primary" plain @click="dialogVisible = true">
                  <!-- {{ $t("Senator.f47@apply_for_jci_senato") }} -->
                  If you cannot find your name, submit here
                </el-button>
              </el-col>
              <el-col class="pt-3" v-if="user_senate==1" :sm="8" :xs="24" :md="8">
                <el-button style="
                    height: 45px;
                    border: 1px solid #E6A23C;
                    background: none;
                    border-radius: 10px;
                    color: #E6A23C;
                  " type="primary" plain disabled>
                  Senatorship submission under review
                </el-button>
              </el-col>
              <el-col class="pt-3" v-if="user_senate==2" :sm="8" :xs="24" :md="8">
                <div style="
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    border: 1px solid #55bea4;
                    background: none;
                    border-radius: 10px;
                    color: #55bea4;
                  ">{{
                  $t("MyJCI.7e2@senator")
                  }} <span style="font-size:12px"> No.{{member_number}}</span>
                </div>
              </el-col>
              <el-col :sm="16" :xs="24" class="pt-3 phoneLeft" :md="16">
                <el-input clearable @clear="memberSearch" @keyup.enter.native="memberSearch" class="w-100"
                  :placeholder="$t('Senator.a72@search_senator')" v-model="search"></el-input>
              </el-col>
            </el-row>
            <el-row class="pt-3 pb-3">
              <el-col :xs="24" :sm="6" class="pb-3">
                <el-select clearable @clear="memberSearch" v-model="nation_id" @change="memberSearch"
                  placeholder="Country" filterable>
                  <el-option v-for="(v, i) in countryList" :key="i" :label="v.name" :value="v.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="18">
                <OrganazationSel @getOrganizationParams="getOrganizationParams" />
              </el-col>
            </el-row>
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <section class="container">
      <!-- <b-button variant="outline-primary" id="payButtonId"> Pay </b-button> -->
      <b-row v-if="loading">
        <b-col v-for="(item, index) in 12" :key="index" md="6" lg="4">
          <el-skeleton animated>
            <template slot="template">
              <iq-card body-class="p-0">
                <div style="padding: 15px;" class="d-flex align-items-center">
                  <el-skeleton-item variant="circle" style="width: 80px; height: 80px;" />
                  <div class="ml-3 d-flex flex-column">
                    <el-skeleton-item variant="text" style="width: 100px;" />
                    <el-skeleton-item variant="text" style="width: 60px;margin-top: 10px;" />
                  </div>
                </div>
                <div style="padding: 10px 15px 9.5px;">
                  <el-skeleton-item variant="button" style="width: 87px;height: 32px" />
                </div>
              </iq-card>
            </template>
          </el-skeleton>
        </b-col>
      </b-row>

      <SenatorCard :senatorList="senatorList" v-if="senatorList.length&&!loading" />
      <div class="d-flex justify-content-end">
        <Pagination :total="total" @changeCurrentPage="changeCurrentPage" />
      </div>
      <b-row v-if="!senatorList.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
        <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" v-if="!loading" />
      </b-row>
    </section>
    <!-- 议员申请弹窗 -->
    <el-dialog :title="$t('Senator.42a@application_form')" :visible.sync="dialogVisible" >
      <div class="form-group">
        <label>{{ $t("MyJCI.327No") }}.</label>
        <input type="text" v-model="senateApply" class="form-control" @input="limitInput" />
      </div>
      <div class="form-group">
        <label>Senate Date</label>
        <el-date-picker v-model="created_at" type="date" style="width: 100%;" value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <p style="word-break: keep-all;">Your application will be submitted to JCI Staff for review. It will be pending approval until reviewed and passed. Once approved, the Senatorship will be displayed on your profile.</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("Senator.24e@cancel")
          }}</el-button>
        <el-button type="primary" @click="getSenateApply">
          {{ $t("Senator.471@comfirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import FullBanner from "../../components/ComComponents/FullBanner";
  import SenatorCard from "./components/senatorCard";
  import CountrySelection from "../../components/ComComponents/CountrySelection";
  import Pagination from "@/components/ComComponents/Pagination";
  import Screen from "@/components/ComComponents/Screen";
  import OrganazationSel from "@/components/ComComponents/OrganazationSel";
  export default {
    data() {
      return {

        senatorList: [],
        user_senate: '',
        total: 1,
        img: "",

        search: "",

        dialogVisible: false,
        form: {
          desc: "",
        },
        params: {},

        imgList: [],
        countryList: [],
        nation_id: "",
        organization_id: "",
        loading: true,

        senateApply: '',
        created_at: '',
        member_number: ''
      };
    },

    components: {
      FullBanner,
      SenatorCard,
      CountrySelection,
      Pagination,
      Screen,
      OrganazationSel
    },

    created() {
      this.memberSearch();
      this.getAddress();
    },

    methods: {
      //限制No.
      limitInput() {
        if (/[^0-9]/g.test(this.senateApply)) {
          this.$message.warning("Only numbers can be entered")
        }
        this.senateApply = this.senateApply.replace(/[^0-9.]/g, "");
      },

      // 筛选
      getOrganizationParams(data) {
        let OrganData = JSON.parse(JSON.stringify(data));
        console.log('data', OrganData);
        let level = OrganData.level.id;
        switch (level) {
          case 0: {
            // jci
            this.organization_id = OrganData.jci.id;
            this.memberSearch();
            break;
          }
          case 4: {
            // area
            this.organization_id = OrganData.area.id;
            this.memberSearch();
            break;
          }
          case 2: {
            // nation
            this.organization_id = OrganData.national.id;
            this.memberSearch();
            break;
          }
          case 3: {
            // region
            this.organization_id = OrganData.regional.id;
            this.memberSearch();
            break;
          }
          case 1: {
            // local
            this.organization_id = OrganData.local.id;
            this.memberSearch();
            break;
          }
        }

      },

      // 获取国家列表
      async getAddress(level = 2) {
        const params = {
          level
        }
        let {
          status,
          data
        } = await this.$http.getAddress(params)
        if (status == 200) {
          this.countryList = data;
        }
      },

      searchProjectFunc(val) {
        this.params = val;
        this.memberSearch(val);
      },

      changeCurrentPage(e) {
        this.page = e;
        this.memberSearch(this.params);
      },

      // 获取议员列表
      memberSearch(val) {
        this.loading = true
        this.$http.memberSearch({
          page: this.page,
          name: this.search,
          nation_id: this.nation_id,
          organization_id: this.organization_id,
        }).then((res) => {
          if (res.status == 200) {
            this.total = res.data.senate.total;
            this.senatorList = res.data.senate.data;
            this.user_senate = res.data.user_senate;
            this.member_number = res.data.member_number;
          }
          setTimeout(() => {
            this.loading = false
          }, 0);
        });
      },
      // 议员申请
      getSenateApply() {
        const data = {
          member_number: this.senateApply,
          created_at: this.created_at
        };
        // console.log(data);
        this.$http.getSenateApply(data).then((res) => {
          if (res.status == 200) {
            this.memberSearch()
            this.dialogVisible = false;
          }
        });
      },

      // 点击移除
      // handleClickRemove (index) {
      //   this.
      // },

      uploadFileIMG(param) {
        let formData = new FormData();
        formData.append("file", param.file);
        this.$http.uploadImage(formData).then((res) => {
          console.log(res);
          this.imgList.push(res.data[0]);
        });
      },
    },
  };

</script>
<style lang="scss" scoped>
@media (min-width: 600px) {
  ::v-deep .el-dialog{
    width: 760px;
  }
  .pcLeft{
    padding-right: 15px;
  }
  .phoneLeft{
    display: none;
  }
}
@media (max-width: 600px) {
  ::v-deep .el-dialog{
    width: 95%;
  }
  .pcLeft{
    display: none;
  }
}
  .Senator {
    min-height: 100vh;

    .searchBox {
      position: relative;

      >img {
        position: absolute;
        top: 22px;
        right: 28px;
      }
    }

    .img-list-box {
      >li {
        width: 90px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 15px;
        position: relative;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        >i.close {
          font-size: 18px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1000;
        }
      }
    }

    ::v-deep .el-form-item__label {
      padding: 0;
      margin: 0;
    }

    ::v-deep .el-input__inner {
      height: 45px;
      border-radius: 11px;
    }

    ::v-deep .el-dialog__body {
      padding-top: 0;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 90px;
      height: 90px;
      line-height: 90px;
      text-align: center;
    }

    .avatar {
      width: 90px;
      height: 90px;
      display: block;
    }
  }
</style>